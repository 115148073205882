import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, CTA } from "../components";

const ContactLenses = ({ data: { strapiDsrCookies } }) => {
  return (
    <PageLayout {...strapiDsrCookies} flourish={1}>
      <CTA />
    </PageLayout>
  );
};

export default ContactLenses;

export const query = graphql`
  query cookiesPageQuery {
    strapiDsrCookies {
      content
      title
    }
  }
`;
